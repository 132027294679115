html, body {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.grecaptcha-badge {
  display: none !important;
}

body * {
  font-optical-sizing: auto;
  font-variation-settings: "YOPQ" 300;
  font-family: Kumbh Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
}

header {
  backdrop-filter: blur(5px);
  z-index: 10;
  background-color: #00000073;
  border-radius: 20px;
  justify-content: center;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translate(-50%);
}

header a {
  color: #fff;
  text-wrap: nowrap;
  margin-right: 20px;
  text-decoration: none;
}

header a:last-child {
  margin-right: 0;
}

#book {
  z-index: 5;
  backdrop-filter: blur(5px);
  color: #fff;
  background: #e7338c93;
  border: 1px solid #ff6fb6;
  border-radius: 8px;
  align-items: center;
  max-width: 90%;
  padding: 8px 16px;
  text-decoration: none;
  transition: width .2s;
  display: flex;
  position: fixed;
  top: 90px;
  left: 50%;
  transform: translate(-50%);
}

#book div {
  align-items: center;
  width: 0;
  margin-left: 8px;
  transition: width .2s;
  display: flex;
  overflow: hidden;
}

#book:hover div {
  width: 20px;
}

#book #cta-text {
  white-space: pre;
  transition: opacity .5s;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 #0f9;
  }

  100% {
    box-shadow: 0 0 10px 10px #0f90;
  }
}

#book > span:first-child {
  background: #0f9;
  border-radius: 50%;
  flex-grow: 1;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  animation: 2s infinite pulse;
  display: inline-block;
}

#projects {
  background: #fbf5f3;
}

#projects > div {
  flex-direction: column;
  margin-bottom: 96px;
  padding: 40px;
  display: flex;
}

#projects .image-container {
  background: #f0a202;
  border-radius: 30px;
  height: 60vh;
  transition: border-radius .5s, transform .5s;
  position: relative;
  overflow: hidden;
}

#projects .image-container:hover {
  border-radius: 0;
  transform: scale(1.01);
}

#projects .image-container > span {
  color: #000;
  background: #fbf5f3;
  border-radius: 10px;
  padding: 8px 16px;
  font-weight: 600;
  text-decoration: none;
  position: absolute;
  top: 20px;
  right: 24px;
}

#projects .image-container > span:last-child {
  bottom: 20px;
  right: 24px;
  top: unset;
}

#projects .image-container img {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  border-radius: 10px;
  width: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#projects > div:nth-child(3n+1) .image-container, #projects > div:nth-child(3n+1) .info .tags span {
  background-color: #4f3d89;
}

#projects > div:nth-child(3n+2) .image-container, #projects > div:nth-child(3n+2) .info .tags span {
  background-color: #e7338c;
}

#projects > div:nth-child(3n+3) .image-container, #projects > div:nth-child(3n+3) .info .tags span {
  background-color: #f0a202;
}

#projects .info {
  flex-wrap: nowrap;
  gap: 16px;
  margin-top: 16px;
  display: flex;
}

#projects .info > div {
  background-color: #fff;
  border-radius: 30px;
  padding: 30px;
}

#projects .info > div:nth-child(2) {
  flex-grow: 1;
}

#projects .info > div:last-child {
  min-width: 15%;
}

#projects .info h2 {
  text-wrap: nowrap;
  margin-top: 0;
  font-size: 32px;
  font-weight: bold;
}

#projects .info .tags span {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: #e7338c;
  border-radius: 18px;
  margin: 3px 3px 0 0;
  padding: 4px 12px;
  font-size: 12px;
  display: inline-block;
}

#projects .info p {
  margin: 0;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

#slideshow {
  overflow: hidden;
}

#slideshow .container {
  white-space: nowrap;
  width: 11184px;
  padding: 40px 0;
  animation: 120s linear infinite scroll;
  position: relative;
}

#slideshow .container img {
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 450px;
  margin-left: 16px;
  display: inline-block;
}

#how, #testimonials {
  background: #fbf5f3;
  padding: 64px 10%;
}

#how h4, #testimonials h4 {
  text-transform: uppercase;
  color: #4f3d89;
  font-size: 14px;
  font-weight: 900;
}

#how h2, #testimonials h2 {
  margin-top: 0;
  font-size: 48px;
  font-weight: 600;
}

#how h2 span {
  color: #e7338c;
  text-shadow: 2px 2px 9px #e7338c80;
  font-weight: 800;
}

#how .container {
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  display: flex;
}

#how .container > div {
  background: #fff;
  border-radius: 12px;
  flex-basis: calc(50% - 112px);
  padding: 24px 36px 24px 64px;
  position: relative;
}

#how .container > div span {
  color: #e7338c;
  text-shadow: 2px 2px 9px #e7338c80;
  z-index: 0;
  font-size: 64px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 10px;
}

#how .container > div h3, #how .container > div p {
  z-index: 2;
}

#how .container > div h3 {
  margin: 32px 0 12px;
  font-size: 24px;
  font-weight: 600;
}

#how .container > div p {
  margin: 0;
}

#testimonials .container {
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

#testimonials .container > div {
  background: #fff;
  border-radius: 18px;
  flex-basis: calc(50% - 96px);
  margin-bottom: 32px;
  margin-right: 32px;
  padding: 32px;
  position: relative;
}

#testimonials .container > div h3 {
  color: #4f3d89;
  margin-top: 32px;
  margin-bottom: 0;
}

#testimonials .container > div h4 {
  margin-top: 4px;
  margin-bottom: 0;
}

#contact {
  background: #fbf5f3;
  padding: 64px;
}

#contact .container {
  background: #130131;
  border-radius: 32px;
  padding: 42px;
}

#contact .container h4 {
  color: #e7338c;
  margin: 0 0 64px;
  font-size: 48px;
}

#contact .container .row {
  display: flex;
}

#contact .container .col {
  flex-basis: 50%;
}

#contact .container h2 {
  color: #fbf5f3;
  font-size: 24px;
}

#contact .container p {
  color: #fbf5f3;
  width: 80%;
}

#contact a {
  color: #fbf5f3;
  background: #e7338c;
  border-radius: 8px;
  margin-top: 36px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 800;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 0 27px #e7338cbf;
}

#contact .container h4 span {
  font-weight: 900;
}

#contact input, #contact textarea {
  background: #fbf5f3;
  border: 0;
  padding: 12px;
}

#contact textarea {
  width: calc(100% - 24px);
}

#contact .input-row {
  gap: 12px;
  display: flex;
}

#contact .input-row input {
  flex-basis: calc(50% - 2px);
}

#contact textarea, #contact button, #contact input {
  border-radius: 8px;
  margin-bottom: 12px;
}

#contact button {
  background: #fbf5f3;
  border: 0;
  width: 100%;
  padding: 12px 0;
  font-weight: bold;
}

#contact #thank-you, #contact #error {
  display: none;
}

#contact #error {
  color: red;
}

#contact form button {
  margin-bottom: 0;
}

footer {
  text-align: center;
  color: #aaa;
  background: #130131;
  padding: 32px 0;
}

footer a {
  color: #aaa;
  margin: 18px 0;
  font-size: 14px;
  display: block;
}

footer .legal {
  margin-top: 32px;
}

@media only screen and (width <= 1100px) {
  #projects .info {
    flex-direction: column;
  }

  #how, #testimonials {
    padding: 64px 5%;
  }

  #testimonials .container > div {
    flex-basis: calc(100% - 64px);
    margin-right: 0;
  }
}

@media only screen and (width <= 900px) {
  #projects .image-container img {
    width: 90%;
  }

  #projects .info h2 {
    text-wrap: wrap;
  }

  #how .container {
    gap: 0;
  }

  #how .container > div {
    flex-basis: calc(100% - 100px);
    margin-bottom: 16px;
  }

  #contact .container .row {
    flex-direction: column;
  }

  #contact .container .col {
    flex-basis: 100%;
  }

  #contact {
    padding-top: 0;
  }
}

@media only screen and (width <= 800px) {
  #projects > div {
    padding: 16px;
  }

  #contact {
    padding: 32px;
  }

  #contact .container {
    padding: 24px;
  }

  #contact .container h4 {
    margin-bottom: 24px;
    font-size: 32px;
  }

  #contact a {
    margin-top: 12px;
    margin-bottom: 36px;
  }
}

@media only screen and (width <= 600px) {
  header {
    display: none;
  }

  #book {
    bottom: unset;
    top: 32px;
  }

  #book div {
    display: none;
  }

  #projects .image-container {
    border-radius: 0;
    height: 50vh;
    margin-left: -16px;
    margin-right: -16px;
  }

  #projects .image-container img {
    border-radius: 0;
    width: 100%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  #how h2 {
    font-size: 28px;
  }

  #contact {
    padding: 64px 5%;
  }

  #contact .input-row {
    flex-direction: column;
    gap: 0;
  }

  #contact .input-row input {
    flex-basis: calc(100% - 2px);
  }
}
/*# sourceMappingURL=index.c438a5dc.css.map */
