html,body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    scroll-behavior: smooth;
}
.grecaptcha-badge {
    display: none !important;
}
body * {
    font-family: "Kumbh Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "YOPQ" 300;
}
header {
    background-color: #00000073;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 24px;
    left: 50%;
    transform: translate(-50%);
    padding: 20px;
    border-radius: 20px;
    z-index: 10;
    justify-content: center;
    display: flex;
}
header a {
    margin-right: 20px;
    text-decoration: none;
    color: #FFF;
    text-wrap: nowrap;
}
header a:last-child {
    margin-right: 0;
}
#book {
    position: fixed;
    top: 90px;
    left: 50%;
    transform: translate(-50%);
    z-index: 5;
    background: #E7338C;
    background-color: #E7338C93;
    backdrop-filter: blur(5px);
    border: 1px solid #FF6FB6;
    color: #FFF;
    padding: 8px 16px;
    border-radius: 8px;
    text-decoration: none;
    display: flex;
    align-items: center;
    max-width: 90%;

    transition: width 0.2s ease;
}
#book div {
    margin-left: 8px;
    width: 0;
    overflow: hidden;
    transition: 0.2s width ease;
    display: flex;
    align-items: center;
}
#book:hover div {
    width: 20px;
}
#book #cta-text {
  transition: opacity 0.5s ease;
  white-space: pre;
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 255, 153, 1.0);
    }
    100% {
        box-shadow: 0 0 10px 10px rgba(0, 255, 153, 0); /* Glowing state */
    }
}
#book > span:first-child {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #00ff99;
    border-radius: 50%;
    animation: pulse 2s infinite; /* Link to the animation, duration, and iteration */
    margin-right: 8px;
    flex-grow: 1;
}
#projects {
    background: #FBF5F3;
}
#projects > div {
    display: flex;
    flex-direction: column;
    padding: 40px;
    margin-bottom: 96px;
}
#projects .image-container {
    height: 60vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    transition: 0.5s border-radius ease, 0.5s transform ease;
    background: #f0a202;
}
#projects .image-container:hover {
    border-radius: 0;
    transform: scale(1.01);
}
#projects .image-container > span {
    position: absolute;
    top: 20px;
    right: 24px;
    background: #FBF5F3;
    padding: 8px 16px;
    border-radius: 10px;
    text-decoration: none;
    color: #000;
    font-weight: 600;
}
#projects .image-container > span:last-child {
    bottom: 20px;
    right: 24px;
    top: unset;
}
#projects .image-container img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    width: 800px;
    border-radius: 10px;
}
#projects > div:nth-child(3n + 1) .image-container,
#projects > div:nth-child(3n + 1) .info .tags span {
    background-color: #4F3D89;
}
#projects > div:nth-child(3n + 2) .image-container,
#projects > div:nth-child(3n + 2) .info .tags span {
    background-color: #E7338C;
}
#projects > div:nth-child(3n + 3) .image-container,
#projects > div:nth-child(3n + 3) .info .tags span {
    background-color: #F0A202;
}
#projects .info {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    margin-top: 16px;
}
#projects .info > div {
    background-color: #FFF;
    border-radius: 30px;
    padding: 30px;
}
#projects .info > div:nth-child(2) {
    flex-grow: 1;
}
#projects .info > div:last-child {
    min-width: 15%;
}
#projects .info h2 {
    margin-top: 0;
    font-weight: bold;
    font-size: 32px;
    text-wrap: nowrap;
}
#projects .info .tags span {
    font-size: 12px;
    display: inline-block;
    background-color: #E7338C;
    border-radius: 18px;
    padding: 4px 12px;
    color: #FFF;
    margin: 3px 3px 0 0;
    user-select: none;
    cursor: pointer;
}
#projects .info p {
    margin: 0;
}
@keyframes scroll {
    from { transform: translateX(0); }
    to { transform: translateX(-100%); }
}
#slideshow {
    overflow: hidden;
}
#slideshow .container {
    white-space: nowrap;
    width: calc((450px + 16px) * 24);
    position: relative;
    padding: 40px 0;
    animation: scroll 120s linear infinite; /* Adjust time for speed */
}
#slideshow .container img {
    width: 450px;
    border: 1px solid #DDD;
    border-radius: 8px;
    margin-left: 16px;
    display: inline-block;
}
#how,
#testimonials {
    background: #FBF5F3;
    padding: 64px 10%;
}
#how h4,
#testimonials h4 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 900;
    color: #4F3D89;
}
#how h2,
#testimonials h2 {
    font-size: 48px;
    font-weight: 600;
    margin-top: 0;
}
#how h2 span {
    font-weight: 800;
    color: #E7338C;
    text-shadow: 2px 2px 9px rgba(231,51,140,0.5);
}
#how .container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
}
#how .container > div {
    flex-basis: calc(50% - 36px - 64px - 12px);
    background: #FFF;
    padding: 24px 36px 24px 64px;
    border-radius: 12px;
    position: relative;
}
#how .container > div span {
    position: absolute;
    font-size: 64px;
    color: #E7338C;
    text-shadow: 2px 2px 9px rgba(231,51,140,0.5);
    font-weight: 600;
    z-index: 0;
    left: 10px;
    top: 0;
}
#how .container > div h3,
#how .container > div p {
    z-index: 2;
}
#how .container > div h3 {
    font-weight: 600;
    font-size: 24px;
    margin: 32px 0 12px 0;
}
#how .container > div p {
    margin: 0;
}

#testimonials .container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}
#testimonials .container > div {
    background: #FFF;
    border-radius: 18px;
    padding: 32px;
    flex-basis: calc(50% - 32px - 32px - 32px);
    margin-right: 32px;
    margin-bottom: 32px;
    position: relative;
}
#testimonials .container > div h3 {
    margin-top: 32px;
    margin-bottom: 0;
    color: #4f3d89;
}
#testimonials .container > div h4 {
    margin-top: 4px;
    margin-bottom: 0;
}
#contact {
    background: #FBF5F3;
    padding: 64px;
}
#contact .container {
    background: #130131;
    border-radius: 32px;
    padding: 42px;
}
#contact .container h4 {
    color: #E7338C;
    font-size: 48px;
    margin: 0 0 64px 0;
}
#contact .container .row {
    display: flex;
}
#contact .container .col {
    flex-basis: 50%;
}
#contact .container h2 {
    font-size: 24px;
    color: #FBF5F3;
}
#contact .container p {
    color: #FBF5F3;
    width: 80%;
}
#contact a {
    display: inline-block;
    margin-top: 36px;
    background: #E7338C;
    color: #FBF5F3;
    font-weight: 800;
    font-size: 18px;
    text-decoration: none;
    padding: 12px 24px;
    border-radius: 8px;
    box-shadow: 0px 0px 27px 0px rgba(231,51,140,0.75);
    -webkit-box-shadow: 0px 0px 27px 0px rgba(231,51,140,0.75);
    -moz-box-shadow: 0px 0px 27px 0px rgba(231,51,140,0.75);
}

#contact .container h4 span {
    font-weight: 900;
}
#contact input,
#contact textarea {
    background: #FBF5F3;
    border: 0;
    padding: 12px;
}
#contact textarea {
    width: calc(100% - 24px);
}
#contact .input-row {
    display: flex;
    gap: 12px;
}
#contact .input-row input {
    flex-basis: calc(50% - 2px);
}
#contact textarea,
#contact button,
#contact input {
    border-radius: 8px;
    margin-bottom: 12px;
}

#contact button {
    width: 100%;
    border: 0;
    padding: 12px 0;
    background: #FBF5F3;
    font-weight: bold;
}
#contact #thank-you,
#contact #error {
    display: none;
}
#contact #error {
    color: red;
}
#contact form button {
    margin-bottom: 0;
}
footer {
    text-align: center;
    background: #130131;
    color: #AAA;
    padding: 32px 0;
}
footer a {
    color: #AAA;
    font-size: 14px;
    margin: 18px 0;
    display: block;
}
footer .legal {
    margin-top: 32px;
}
@media only screen and (max-width: 1100px) {
    #projects .info {
        flex-direction: column;
    }

    #how,
    #testimonials {
        padding: 64px 5%;
    }

    #testimonials .container > div {
        flex-basis: calc(100% - 32px - 32px);
        margin-right: 0;
    }
}
@media only screen and (max-width: 900px) {
    #projects .image-container img {
        width: 90%;
    }
    #projects .info h2 {
        text-wrap: wrap;
    }
    #how .container {
        gap: 0;
    }
    #how .container > div {
        flex-basis: calc(100% - 36px - 64px);
        margin-bottom: 16px;
    }
    #contact .container .row {
        flex-direction: column;
    }
    #contact .container .col {
        flex-basis: 100%;
    }
    #contact {
        padding-top: 0;
    }
}
@media only screen and (max-width: 800px) {
    #projects > div {
        padding: 16px;
    }
    #contact {
        padding: 32px;
    }
    #contact .container {
        padding: 24px;
    }
    #contact .container h4 {
        font-size: 32px;
        margin-bottom: 24px;
    }
    #contact a {
        margin-top: 12px;
        margin-bottom: 36px;
    }
}
@media only screen and (max-width: 600px) {
    header {
        display: none;
    }
    #book {
        bottom: unset;
        top: 32px;
    }
    #book div {
        display: none;
    }

    #projects .image-container {
      height: 50vh;
      margin-left: -16px;
      margin-right: -16px;
      border-radius: 0;
    }
    #projects .image-container img {
      width: 100%;
      left: 0;
      right: 0;
      border-radius: 0;
      transform: translateY(-50%);
    }
    #how h2 {
      font-size: 28px;
    }
    #contact {
        padding: 64px 5%;
    }

    #contact .input-row {
        flex-direction: column;
        gap: 0;
    }
    #contact .input-row input {
        flex-basis: calc(100% - 2px);
    }
}
